import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/number3.jpg","alt":"Previous Explorations","title":"Previous Explorations"}}></MDXTag></MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"Explore Gabii","key":"explore-gabii"};

  